<template>
  <div>
    <b-d44-tooltip
      v-if="status && itemUserId !== userId"
      :status="status"
      :error-text="ERROR_STATUS_TEXT"
    />
    <v-menu
      v-if="status && itemUserId === userId"
      offset-y
      nudge-left="400px"
      :close-on-content-click="false"
      @input="onMenuInput"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          min-width="36px"
          width="36px"
          text
          class="elevation-0"
          :class="{
            'px-0':mobile
          }"
          v-bind="attrs"
          v-on="on"
          @click="getStatusList"
        >
          <v-icon
            :color="status === ERROR_STATUS_TEXT ? 'red': 'dark'"
          >
            {{ status === ERROR_STATUS_TEXT ? 'mdi-database-remove' : 'mdi-database' }}
          </v-icon>
        </v-btn>
      </template>
      <div class="white">
        <div
          class="px-4 py-2 white--text "
          :class="status === ERROR_STATUS_TEXT ? 'red': 'green'"
        >
          <b>{{ status }} {{ $options.ERROR_STATUS_TEXT }}</b>
        </div>
        <status-list
          :status-list="statusList"
        />
      </div>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import StatusList from "@/components/journal/status-list-bd44.vue";
import BD44Tooltip from "@/components/journal/bd44-tooltip.vue";


export default {
  components: {BD44Tooltip, StatusList},
  props:{
    status:{
      required:true,
      validator:(v) => typeof v === 'string' || v === null,
      default:null
    },
    itemUserId:{
      required:true,
      type:Number
    },
    requests:{
      required:true,
      validator:(v) => v === null || Array.isArray(v),
      default:null
    }
  },
  data:() => ({
    ERROR_STATUS_TEXT:'Отказ в принятии',
    statusList:[],
    statusMessage: {},
  }),
  computed:{
    ...mapGetters({
      user:'auth/getUser',
    }),
    userId(){
      return this.user?.user?.id
    },
    mobile(){
      return this.$vuetify.breakpoint.mobile
    },
    nudgeTop(){
      return '100px';
    },
    width(){
      if(this.mobile){
        return '100vw'
      }
      return [15].includes(this.messageType) ? '700px':'500px'
    },

  },
  methods:{
    getStatusList(){
      if(this.itemUserId !== this.userId) return false
      const promises = this.requests.map((request) => this.getSingleStatus(request.id))
      Promise.all(promises).then(statuses => {
        const files = []
        statuses.forEach((status) => {
          if(status?.files?.length) files.push(...status.files)
        })
        this.statusList = files
      }).catch(() => this.$error('Не удалось загрузить список'))
    },
    getSingleStatus(requestId){
      return this.$store.dispatch('journal/getEcdCstmList', requestId).then(res => res.data)
    },
    getStatusMessage(item){
      this.$store.dispatch('journal/getStatusMessage', item).then(res => {
        this.statusMessage = res.data
      }).catch(err => {
        this.$snackbar({text:err.response.data, color:"red", top:false, right: false});
      })
    },
    onMenuInput(value){
      if(value === false){
        this.statusList = [];
        this.statusMessage = {}
      }
    },
  }
}
</script>
<style scoped>
.status-description{
  word-break: break-word;
}
</style>
