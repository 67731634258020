<template>
  <v-list>
    <v-list-item>
      <span class="mx-2 font-weight-bold">
        Регистрационный номер "A":
      </span>
    </v-list-item>
    <v-list-item>
      <p class="mx-2 mb-2 status-description">
        {{ statusMessage.regNumberA }}
      </p>
    </v-list-item>
  </v-list>
</template>
<script>
export default {
  props: {
    statusMessage: {
      type: Object,
      required: true
    }
  },
}
</script>