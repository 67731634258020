<template>
  <v-card
    height="350px"
    class="overflow-y-auto"
  >
    <v-card-text v-html="statusMessage" />
  </v-card>
</template>
<script>
export default {
  props:{
    statusMessage:{
      type:String,
      required:true
    }
  }
}
</script>