<template>
  <v-tooltip
    bottom
    max-width="300"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        min-width="36px"
        width="36px"
        text
        class="elevation-0"
        :class="{
          'px-0':mobile
        }"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          :color="status === errorText ? 'red': 'dark'"
        >
          {{ status === errorText ? 'mdi-database-remove' : 'mdi-database' }}
        </v-icon>
      </v-btn>
    </template>
    <span>{{ status }}</span>
  </v-tooltip>
</template>
<script>
export default {
  props:{
    status:{
      required:true,
      type: String
    },
    errorText:{
      required:true,
      type: String
    }
  },
  computed:{
    mobile(){
      return this.$vuetify.breakpoint.mobile
    },
  }
}
</script>