<template>
  <v-simple-table
    v-if="statusList.length"
    class="pt-2"
  >
    <tbody>
      <tr
        v-for="(element, idx) in statusList"
        :key="'status-item-'+idx"
      >
        <td>{{ element.status }}</td>
        <td>{{ element.date_of | dateFilter }}</td>
        <td>
          <v-menu
            offset-overflow
            nudge-left="500px"
            :close-on-content-click="false"
            max-height="400px"
            max-width="500px"
          >
            <template #activator="{ on: on2, attrs:attrs2 }">
              <v-btn
                plain
                v-bind="attrs2"
                :disabled="!element.is_client_available"
                color="primary"
                v-on="on2"
                @click="getStatusMessage(element)"
              >
                <v-icon>
                  mdi-comment
                </v-icon>
              </v-btn>
            </template>
            <html-card
              v-if="messageType === 'string'"
              :status-message="statusMessage"
            />
            <entries-list
              v-else-if="messageType === 2"
              :status-message="statusMessage"
            />

            <entries-table
              v-else-if="messageType === 15"
              :status-message="statusMessage"
            />
            <regnum-a
              v-else-if="messageType === 3"
              :status-message="statusMessage"
            />
            <regnum-b
              v-else-if="messageType === 5"
              :status-message="statusMessage"
            />
            <single-reason
              v-else-if="messageType === 9"
              :status-message="statusMessage"
            />
          </v-menu>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>
<script>
import {mapGetters} from "vuex";
import HtmlCard from "@/components/journal/status-wrappers/html-card.vue";
import EntriesList from "@/components/journal/status-wrappers/entries-list.vue";
import EntriesTable from "@/components/journal/status-wrappers/entries-table.vue";
import RegnumA from "@/components/journal/status-wrappers/regnum-a.vue";
import RegnumB from "@/components/journal/status-wrappers/regnum-b.vue";
import SingleReason from "@/components/journal/status-wrappers/single-reason.vue";
import moment from "moment";

export default {
  components: {SingleReason, RegnumB, RegnumA, EntriesTable, EntriesList, HtmlCard},
  filters:{
    dateFilter(date){
      return moment(date).format('DD.MM.YYYY H:m:s')
    }
  },
  props:{
    statusList:{
      required:true,
      type:Array
    }
  },
  data:() => ({
    statusMessage:null
  }),
  computed:{
    ...mapGetters({
      user:'auth/getUser'
    }),
    userId(){
      return this.user?.user?.id
    },
    mobile(){
      return this.$vuetify.breakpoint.mobile
    },
    nudgeTop(){
      if(this.mobile){
        return '200px'
      }
      if([3,5].includes(this.messageType)) return '100px';
      return [15].includes(this.messageType) ? '300px':'200px'
    },
    listWidth(){
      if(this.mobile) return '95vw';
      return 'auto'
    },
    width(){
      if(this.mobile){
        return '100vw'
      }
      return [15].includes(this.messageType) ? '700px':'500px'
    },

    messageType(){
      if(this.statusMessage instanceof Object){
        if('reasonCode' in this.statusMessage && 'description' in this.statusMessage){
          return 2
        }else if('reason' in this.statusMessage && 'entries' in this.statusMessage){
          return 15
        }else if('regNumberA' in this.statusMessage){
          return 3
        }else if('regNumberB' in this.statusMessage){
          return 5
        }else{
          return 9
        }
      }else if(typeof this.statusMessage === 'string'){
        return 'string'
      }
      return null
    },
  },
  methods:{
    getStatusMessage(item){
      this.$store.dispatch('journal/getEcdCstmDetails', item).then(res => {
        this.statusMessage = res.data
      }).catch(err => this.$error(err.response.data))
    },
  }
}
</script>
