<template>
  <v-list
    :width="listWidth"
  >
    <v-list-item>
      <span class="mx-2 font-weight-bold">Причина: </span>
      {{ statusMessage.reason }}
    </v-list-item>
    <v-simple-table
      class="mx-5 mb-2"
      height="300px"
      fixed-header
    >
      <template #default>
        <thead class="text-left">
          <th class="px-4">
            Поле
          </th>
          <th class="px-4">
            Раздел
          </th>
          <th class="px-4">
            Ошибка
          </th>
        </thead>
        <tbody>
          <tr
            v-for="(en, entryIndex) in statusMessage.entries"
            :key="'entries-'+entryIndex"
          >
            <td>
              <v-chip
                v-if="'wareNumber' in en"
                small
                color="secondary"
                class="mt-1"
              >
                Товар №{{ en.wareNumber }}
              </v-chip>
              {{ en.field }}
            </td>
            <td>
              {{ en.section }}
            </td>
            <td>
              {{ en.text }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-list>
</template>

<script>
export default {
  props: {
    statusMessage: {
      type: Object,
      required: true
    }
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mobile
    },
    listWidth() {
      if (this.mobile) return '95vw';
      return 'auto'
    },
  }
}
</script>

