<template>
  <v-list>
    <v-list-item>
      <span class="mx-2 font-weight-bold">
        {{ statusMessage.reason }}
      </span>
    </v-list-item>
  </v-list>
</template>
<script>
export default {
  props: {
    statusMessage: {
      type: Object,
      required: true
    }
  },
}
</script>