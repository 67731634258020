<template>
  <v-list
    :width="listWidth"
  >
    <v-list-item>
      <span class="mx-2 font-weight-bold">Код: </span>
      {{ statusMessage.reasonCode }}
    </v-list-item>
    <v-list-item>
      <p class="mx-2 mb-2 status-description">
        {{ statusMessage.description }}
      </p>
    </v-list-item>
    <template
      v-if="'entries' in statusMessage && Array.isArray(statusMessage.entries) && statusMessage.entries.length"
    >
      <v-list-item
        v-for="(error, index) in statusMessage.entries"
        :key="`err-${index}`"
      >
        <p class="mx-2">
          {{ error.text }} <b v-if="error.wareNumber">Товары:</b> {{ error.wareNumber }}
        </p>
      </v-list-item>
    </template>
  </v-list>
</template>
<script>
export default {
  props: {
    statusMessage: {
      type: Object,
      required: true
    }
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mobile
    },
    listWidth() {
      if (this.mobile) return '95vw';
      return 'auto'
    },
  }
}
</script>